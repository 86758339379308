<template>
  <div>
    <div class="main-img-wrapper">
      <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/f3e8e82e-88fd-4ddc-98a5-d7809c5da585.jpg" alt="主图" class="img-fluid">
      <div class="main-img-caption flex flex-column flex-center">
        <h1 class="main-img-title animated slideInDown">AI人脸识别</h1>
        <p class="animated slideInDown">国内母婴行业<strong>首家</strong>AI人脸识别技术</p>
      </div>
    </div>
    <div class="ai-list stripe">
      <b-container>
        <h1 data-v-fae5bece="" class="text-center main-title animated fadeInUp">四大核心</h1>
        <b-row>
          <b-col cols="6" md="3" class="list-item-wrapper">
            <div class="list-item">
              <b-row class="flex-center">
                <b-col cols="6" sm="6">
                  <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/31d92e08-acdd-4186-b0f9-b6cff9b53378.png" alt="电子名片"></div>
                </b-col>
              </b-row>
              <h3 class="title">电子名片</h3>
            </div>
          </b-col>
          <b-col cols="6" md="3" class="list-item-wrapper">
            <div class="list-item">
              <b-row class="flex-center">
                <b-col cols="6" sm="6">
                  <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/8594ab75-1704-4382-8e2c-9fdc51c828fd.png" alt="精准维客"></div>
                </b-col>
              </b-row>
              <h3 class="title">精准维客</h3>
            </div>
          </b-col>
          <b-col cols="6" md="3" class="list-item-wrapper">
            <div class="list-item">
              <b-row class="flex-center">
                <b-col cols="6" sm="6">
                  <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/4b33f3dd-36f4-4cce-bb7f-1252164528a7.png" alt="高效营销"></div>
                </b-col>
              </b-row>
              <h3 class="title">高效营销</h3>
            </div>
          </b-col>
          <b-col cols="6" md="3" class="list-item-wrapper">
            <div class="list-item">
              <b-row class="flex-center">
                <b-col cols="6" sm="6">
                  <div class="img-wrapper"><img class="img-fluid" src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/5f583ae4-5851-4e6f-82ef-c2487ec67aa7.png" alt="轻松社交"></div>
                </b-col>
              </b-row>
              <h3 class="title">轻松社交</h3>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <section class="ai-detail">
      <b-container>
        <b-row class="flex-center hm-title">
          <b-col cols="3">
            <hr />
          </b-col>
          <b-col cols="6" class="text-center">
            <h3>系统组成</h3>
          </b-col>
          <b-col cols="3">
            <hr />
          </b-col>
        </b-row>
        <b-row class="flex-center mb100">
          <b-col cols="12">
            <img src="../assets/ai.gif" alt="系统组成" class="img-fluid pc">
            <img src="../assets/ai-mobile.gif" alt="系统组成" class="img-fluid mobile">
          </b-col>
        </b-row>
        <b-row class="flex-center hm-title">
          <b-col cols="3">
            <hr />
          </b-col>
          <b-col cols="6" class="text-center">
            <h3>实现5秒营销</h3>
          </b-col>
          <b-col cols="3">
            <hr />
          </b-col>
        </b-row>
        <b-row class="flex-center mb100">
          <b-col cols="12" sm="8" ref="img" class="animated" :class="img">
            <img class="img-fluid" src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/e94b923c-2864-4b29-a81e-f6df58114558.png" alt="实现5秒营销">
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>

export default {
  data () {
    return {
      img: ''
    }
  },
  mounted () {
    // window.addEventListener('scroll', () => {
    //   const scrollDistance = document.documentElement.scrollTop || document.body.scrollTop
    //   this.addAnimate({ ele: 'img', model: 'img', animate: 'fadeInUp', scrollDistance })
    // })
  },
  destoryed () {
    // window.removeEventListener('scroll')
  },
  methods: {
    // addAnimate ({ ele, model, animate, space = 200, scrollDistance }) {
    //   if (this.$refs[ele]) {
    //     this[model] = scrollDistance + space >= this.$refs[ele].offsetTop ? animate : ''
    //   }
    // }
  }
}
</script>

<style scoped lang="less">
@distance: 40px;
.ai-list {
  padding: @distance 0;
  .list-item {
    text-align: center;
    cursor: pointer;
    padding: @distance 0;
    height: 100%;
    background: #fff;
    transition: all 0.2s linear;
    box-shadow: 0px 3px 3px rgba(50, 50, 50, 0.1);
    border-radius: 5px;
    width: 80%;
    margin: 0 auto;
  }
  .list-item:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
  .title {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
  }
}
.ai-detail {
  padding: @distance 0;
}

.hm-title:first-child {
  padding-top: 0;
}

.hm-title {
  padding: @distance 0;
}

.main-title {
  margin-bottom: 50px;
  color: #333;
  font-size: 24px;
}

.pc {
  display: block
}

.mobile {
  display: none
}

@media (max-width: 767px) {
  .ai-list {
    .list-item-wrapper:first-child,
    .list-item-wrapper:nth-child(2) {
      margin-bottom: @distance;
    }
  }
  .pc {
  display: none
}

.mobile {
  display: block
}
  .main-title {
    margin-bottom: 40px;
  }

  .mb100  {
    margin-bottom: 40px;
  }

  .hm-title {
    h3 {
      font-size: 20px;
    }
  }
}
</style>
